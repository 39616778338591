import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import { HeroDefault } from 'components/hero';
import { withPreview } from 'gatsby-source-prismic';
import {
  TextLeftImageRight,
  GridTypeOne,
  ThreeColumnIcons,
  ThreeQuarterImage,
  StandaloneButton,
  CenteredTitle,
  ParallaxSections,
  ImageGridStyleTwo,
  EstateMap,
  StandaloneImage,
} from 'components/slices';
import Spacer from 'components/spacer';

function Page(props) {
  const { data } = props;
  const doc = data.prismicPage;
  if (!doc) return null;
  return (
    <Layout>
      <HeroDefault data={doc} />
      {doc.data.body && (
        <React.Fragment>
          {doc.data.body.map((item, i) => {
            switch (item.slice_type) {
              case 'text_left_image_right':
                return (
                  <React.Fragment key={i}>
                    <Spacer
                      spacerTop={item.primary.spacer_top}
                      spacerBottom={item.primary.spacer_bottom}
                    >
                      <TextLeftImageRight
                        data={item.primary}
                        items={item.items}
                      />
                    </Spacer>
                  </React.Fragment>
                );
              case 'image_grid_style_one':
                return (
                  <React.Fragment key={i}>
                    <Spacer
                      spacerTop={item.primary.spacer_top}
                      spacerBottom={item.primary.spacer_bottom}
                    >
                      <GridTypeOne data={item.primary} />
                    </Spacer>
                  </React.Fragment>
                );
              case 'three_column_icons':
                return (
                  <React.Fragment key={i}>
                    <Spacer
                      spacerTop={item.primary.spacer_top}
                      spacerBottom={item.primary.spacer_bottom}
                    >
                      <ThreeColumnIcons data={item.items} />
                    </Spacer>
                  </React.Fragment>
                );
              case 'three_quarter_image':
                return (
                  <React.Fragment key={i}>
                    <Spacer
                      spacerTop={item.primary.spacer_top}
                      spacerBottom={item.primary.spacer_bottom}
                    >
                      <ThreeQuarterImage data={item.primary} />
                    </Spacer>
                  </React.Fragment>
                );
              case 'standalone_button':
                return (
                  <React.Fragment key={i}>
                    <Spacer
                      spacerTop={item.primary.spacer_top}
                      spacerBottom={item.primary.spacer_bottom}
                    >
                      <StandaloneButton data={item.primary} />
                    </Spacer>
                  </React.Fragment>
                );
              case 'centered_title':
                return (
                  <React.Fragment key={i}>
                    <Spacer
                      spacerTop={item.primary.spacer_top}
                      spacerBottom={item.primary.spacer_bottom}
                    >
                      <CenteredTitle data={item.primary} />
                    </Spacer>
                  </React.Fragment>
                );
              case 'parallax_sections':
                return <ParallaxSections data={item.items} key={i} />;
              case 'image_grid_style_two':
                return (
                  <React.Fragment key={i}>
                    <Spacer
                      spacerTop={item.primary.spacer_top}
                      spacerBottom={item.primary.spacer_bottom}
                    >
                      <ImageGridStyleTwo data={item.primary} />
                    </Spacer>
                  </React.Fragment>
                );
              case 'estate_map':
                return (
                  <React.Fragment key={i}>
                    <EstateMap data={item.primary} />
                  </React.Fragment>
                );
              case 'standalone_image':
                return (
                  <React.Fragment key={i}>
                    <Spacer
                      spacerTop={item.primary.spacer_top}
                      spacerBottom={item.primary.spacer_bottom}
                    >
                      <StandaloneImage data={item.primary} />
                    </Spacer>
                  </React.Fragment>
                );
              default:
                return null;
            }
          })}
        </React.Fragment>
      )}
    </Layout>
  );
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
};

export default withPreview(Page);

export const query = graphql`
  query StandardPageQuery($id: String!) {
    prismicPage(id: { eq: $id }) {
      uid
      data {
        banner_image {
          url
          fluid {
            srcSet
          }
          alt
        }
        body {
          ... on PrismicPageBodyTextLeftImageRight {
            slice_type
            primary {
              spacer_top
              spacer_bottom
              text {
                html
                raw
                text
              }
              image {
                alt
                url
                fluid {
                  srcSet
                }
              }
            }
            items {
              repeatable_image {
                alt
                url
                fluid {
                  srcSet
                }
              }
            }
          }
          ... on PrismicPageBodyImageGridStyleOne {
            slice_type
            primary {
              spacer_top
              spacer_bottom
              content {
                html
                raw
                text
              }
              full_width_image {
                alt
                url
                fluid {
                  srcSet
                }
              }
              grid_footer_content {
                html
                raw
                text
              }
              grid_footer_full_width_image {
                alt
                url
                fluid {
                  srcSet
                }
              }
              grid_footer_image_square {
                alt
                url
                fluid {
                  srcSet
                }
              }
              portrait_image {
                alt
                url
                fluid {
                  srcSet
                }
              }
              square_tile {
                alt
                url
                fluid {
                  srcSet
                }
              }
              square_tile_small {
                alt
                url
                fluid {
                  srcSet
                }
              }
              square_tile_title {
                html
                raw
                text
              }
            }
          }
          ... on PrismicPageBodyThreeColumnIcons {
            slice_type
            primary {
              spacer_top
              spacer_bottom
            }
            items {
              title {
                html
                text
                raw
              }
              image {
                alt
                url
                fluid {
                  srcSet
                }
              }
            }
          }
          ... on PrismicPageBodyThreeQuarterImage {
            slice_type
            primary {
              spacer_top
              spacer_bottom
              image {
                alt
                url
                fluid {
                  srcSet
                }
              }
            }
          }
          ... on PrismicPageBodyStandaloneButton {
            slice_type
            primary {
              spacer_top
              spacer_bottom
              link {
                uid
                type
                id
              }
              link_text {
                html
                raw
                text
              }
            }
          }
          ... on PrismicPageBodyCenteredTitle {
            slice_type
            primary {
              spacer_bottom
              spacer_top
              title {
                html
                raw
                text
              }
            }
          }
          ... on PrismicPageBodyParallaxSections {
            slice_type
            primary {
              spacer_bottom
              spacer_top
            }
            items {
              content {
                html
                raw
                text
              }
              image {
                alt
                url
                fluid {
                  srcSet
                }
              }
              title {
                html
                raw
                text
              }
              link {
                url
                uid
                type
                id
              }
            }
          }
          ... on PrismicPageBodyImageGridStyleTwo {
            slice_type
            primary {
              spacer_top
              spacer_bottom
              content {
                html
                raw
                text
              }
              image_five {
                alt
                url
                fluid {
                  srcSet
                }
              }
              image_four {
                alt
                url
                fluid {
                  srcSet
                }
              }
              image_one {
                alt
                url
                fluid {
                  srcSet
                }
              }
              image_six {
                alt
                url
                fluid {
                  srcSet
                }
              }
              image_three {
                alt
                url
                fluid {
                  srcSet
                }
              }
              image_two {
                alt
                url
                fluid {
                  srcSet
                }
              }
            }
          }
          ... on PrismicPageBodyEstateMap {
            slice_type
            primary {
              spacer_bottom
              spacer_top
              title {
                html
                raw
                text
              }
            }
          }
          ... on PrismicPageBodyStandaloneImage {
            slice_type
            primary {
              spacer_top
              spacer_bottom
              image {
                alt
                url
                fluid {
                  srcSet
                }
              }
            }
          }
        }
      }
    }
  }
`;
